import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=96d1ff96&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=96d1ff96&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96d1ff96",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SMicrodatas: require('/src/components/SMicrodatas.vue').default,SSearchForm: require('/src/components/SSearchForm.vue').default,SSsrLink: require('/src/components/SSsrLink.vue').default})
